export const chunkArray = (arrayToChunk, chunkSize) => {
  let chunkedArray = [];
  const arrayCopy = [...arrayToChunk];

  while (arrayCopy.length) {
    chunkedArray.push(arrayCopy.splice(0, chunkSize));
  }

  return chunkedArray;
};

export const progressPromise = (promises, tickCallback) => {
  var len = promises.length;
  var progress = 0;

  function tick(promise) {
    promise.then(() => {
      progress++;
      tickCallback(progress, len);
    });
    return promise;
  }

  return Promise.all(promises.map(tick));
};

export const addQuotesToCsvValue = (field) => {
  return `"${field.replace(/"/g, '""')}"`;
};

export const convertToCsv = (array, keys) => {
  const header = keys.join(',');
  const rows = array.map((obj) => keys.map((key) => addQuotesToCsvValue(obj[key]) || '').join(',')).join('\n');
  return `${header}\n${rows}`;
};
